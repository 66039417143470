import React, { createContext, useContext, useRef, useState } from "react";
import { Toast, ToastMessage } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";

interface IToastContext {
   show: (props: ToastMessage) => void;
   setLoading: (props: boolean) => void;
}

const ToastContext = createContext<IToastContext>({
   show: () => {
      // this is intentional
   },
   setLoading: () => {
      // this is intentional
   },
});

export const useToast = () => {
   return useContext(ToastContext);
};

export default function ToastProvider({ children }: { children: React.ReactNode | React.ReactNode[] }) {
   const toast = useRef<Toast | null>(null);
   const [loading, setLoading] = useState(false);

   const show = ({ summary = "alert", detail = "", ...otherProps }: ToastMessage) => {
      toast.current?.show({
         severity: "success",
         summary: summary,
         detail: detail,
         life: 3000,
         ...otherProps,
      });
   };
   return (
      <ToastContext.Provider value={{ show, setLoading }}>
         <Toast ref={toast} />
         {loading && <ProgressBar className="fixed top-0 left-0 w-full" mode="indeterminate" style={{ height: "4px" }}></ProgressBar>}

         {children}
      </ToastContext.Provider>
   );
}
